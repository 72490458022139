<template>
    <main class="order order-flight">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="navsteps navstepts-flight">
                        <div class="navsteps__item">
                            <div class="number">1</div>
                            <div class="descr">Разрахунок</div>
                        </div>
                        <div class="navsteps__item">
                            <div class="number">2</div>
                            <div class="descr">Вибір СК</div>
                        </div>
                        <div class="navsteps__item active">
                            <div class="number">3</div>
                            <div class="descr">Оформлення</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="orders osago">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <!--                        <router-link :to="{ path: $routerHistory.previous().path }" class="go-back">-->
                        <a href="javascript:void(0);" class="go-back" @click="$router.go(-1)">
                            Повернутися до вибору
                        </a>
                        <!--                        </router-link>-->
                        <div v-if="offerInfo" class="params-search">
                            <a class="params-search__toggler collapsed" data-toggle="collapse" data-target="#searchcollapse" aria-expanded="false" aria-controls="#searchcollapse">
                                <span>Параметри полісу</span>
                                <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.2795 2.75059e-08L11 0.69496L5.5 6L-2.31891e-07 0.694961L0.7205 4.45343e-07L5.50275 4.61273L10.2795 2.75059e-08Z" fill="black"/>
                                </svg>
                            </a>
                            <div class="collapse" id="searchcollapse">
                                <div class="params-search__content">
                                    <div class="info">
                                        <b>Страхування квитка</b>
                                        <div class="logo">
                                            <img v-if="offerInfo.picture" :src="offerInfo.picture" alt="logo">
                                            <img v-else src="images/aboutUs-insurance_icon.svg" alt="logo">
                                        </div>
                                    </div>
                                    <div class="params">
                                        <div class="params-item">
                                            <p>Франшиза:</p>
                                            <span>0 грн</span>
                                        </div>
                                        <div class="params-item">
                                            <p>100% вартості квитка:</p>
                                            <span>{{searchInfo.ticketPrice}} грн</span>
                                        </div>
                                        <div class="params-item">
                                            <p>Вартість:</p>
                                            <span v-if="offerInfo.fullPrice">{{offerInfo.fullPrice}} грн</span>
                                            <span v-else>{{offerInfo.price}} грн</span>
                                        </div>
                                    </div>
                                    <div class="params-price">
                                        <p>Загальна вартість:</p>
                                        <b v-if="offerInfo.fullPrice">{{offerInfo.fullPrice}} <span>грн</span></b>
                                        <b v-else>{{offerInfo.price}} <span>грн</span></b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <form class="step-block">
                            <div class="row">
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-12">
                                                    <h3>
                                                        <span>Дані страхувальника (Покупець)</span>
                                                        <i data-placement="bottom" data-toggle="tooltip" title="" data-original-title="Подсказка" class="icon question"></i>
                                                    </h3>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div class="form-group">
                                                        <input v-model="info.inn" v-mask="'##########'" type="text" placeholder="ІПН" :class="['form-control', {invalid: (!$v.info.inn.required && submitCheck)}]">
                                                        <small class="error" v-if="!$v.info.inn.required && submitCheck">Вкажіть ІПН</small>
                                                        <small class="error" v-if="!$v.info.inn.numeric">ІПН повинен містити тільки цифри</small>
                                                        <small class="error" v-else-if="!$v.info.inn.minLength">ІПН повинен містити мінімум 10 символів</small>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div class="form-group">
                                                        <input v-model="info.name" type="text" placeholder="Ім’я" :class="['form-control', {invalid: (!$v.info.name.required && submitCheck)}]">
                                                        <small class="error" v-if="!$v.info.name.required && submitCheck">Вкажіть ім’я</small>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div class="form-group">
                                                        <input v-model="info.surname" type="text" placeholder="Прізвище" :class="['form-control', {invalid: (!$v.info.surname.required && submitCheck)}]">
                                                        <small class="error" v-if="!$v.info.surname.required && submitCheck">Вкажіть прізвище</small>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div class="form-group">
                                                        <input v-model.trim="info.dateBirth" v-mask="'##.##.####'" type="text" placeholder="Дата народження" :class="['form-control', 'date-input', {invalid: (!$v.info.dateBirth.required && submitCheck)}]">
                                                        <small class="error" v-if="!$v.info.dateBirth.required && submitCheck">Вкажіть дату народження</small>
                                                        <small class="error" v-else-if="!$v.info.dateBirth.minLength">Введіть дату в форматі12.12.2012</small>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div class="form-group">
                                                        <input v-model.trim="info.phone" v-mask="'+38 (###) ###-##-##'" type="text" placeholder="Телефон" :class="['form-control', {invalid: (!$v.info.phone.required && submitCheck)}]">
                                                        <small class="error" v-if="!$v.info.phone.required && submitCheck">Вкажіть телефон</small>
                                                        <small class="error" v-else-if="!$v.info.phone.minLength">Введіть номер в форматі 380xxxxxxxx</small>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div class="form-group">
                                                        <input v-model.trim="info.mail" type="text" placeholder="Ел. пошта" :class="['form-control', {invalid: (!$v.info.mail.required && submitCheck)}]">
                                                        <small class="error" v-if="!$v.info.mail.required && submitCheck">Вкажіть пошту</small>
                                                        <small class="error" v-else-if="!$v.info.mail.email">Введіть коректно email</small>
                                                    </div>
                                                </div>
                                                <div class="col-lg-3">
                                                    <div class="form-group">
                                                        <citySearch
                                                            :citySelect='citySelect'
                                                            v-bind:cityStore="cityStore"
                                                        />
                                                        <small class="error" v-if="!$v.info.city.required && submitCheck">Вкажіть місто</small>
                                                    </div>
                                                </div>
                                                <div class="col-lg-3">
                                                    <div class="form-group">
                                                        <input v-model="info.street" type="text" placeholder="Вул." :class="['form-control', {invalid: (!$v.info.street.required && submitCheck)}]">
                                                        <small class="error" v-if="!$v.info.street.required && submitCheck">Вкажіть вулицю</small>
                                                    </div>
                                                </div>
                                                <div class="col-lg-3">
                                                    <div class="form-group">
                                                        <input v-model="info.house" type="text" placeholder="№ буд." :class="['form-control', {invalid: (!$v.info.house.required && submitCheck)}]">
                                                        <small class="error" v-if="!$v.info.house.required && submitCheck">Вкажіть № будинку</small>
                                                    </div>
                                                </div>
                                                <div class="col-lg-3">
                                                    <div class="form-group">
                                                        <input v-model="info.apartment" type="text" placeholder="№ кв." :class="['form-control', {invalid: (!$v.info.apartment.required && submitCheck)}]">
                                                        <small class="error" v-if="!$v.info.apartment.required && submitCheck">Вкажіть № квартири</small>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <h3 class="mt">
                                                        <span>Документи</span>
                                                        <i data-placement="bottom" data-toggle="tooltip" title="" data-original-title="Подсказка" class="icon question"></i>
                                                    </h3>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="radiolist">
                                                        <div class="radioitem">
                                                            <input type="radio" name="documentType" :id="1" :checked="info.documentType === 1">
                                                            <label for="1" @click="info.documentType = 1">Паспорт</label>
                                                            <div class="check"></div>
                                                        </div>
                                                        <div class="radioitem">
                                                            <input type="radio" name="documentType" :id="2" :checked="info.documentType === 2">
                                                            <label for="2" @click="info.documentType = 2">Закордонний паспорт</label>
                                                            <div class="check"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4" v-if="info.documentType === 2">
                                                    <div class="form-group">
                                                        <input v-model="info.internationalPassport" type="text" placeholder="Серія та номер закордонного паспорту" :class="['form-control', {invalid: (!$v.info.internationalPassport.required && submitCheck)}]">
                                                        <small class="error" v-if="!$v.info.internationalPassport.required && submitCheck">Вкажіть серію та номер закордонного паспорту</small>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 row passport-row" v-else>
                                                    <div class="col-lg-4">
                                                        <div class="form-group">
                                                            <input v-model="info.passportNumber" type="text" placeholder="Серія та номер паспорта" :class="['form-control', {invalid: (!$v.info.passportNumber.required && submitCheck)}]">
                                                            <small class="error" v-if="!$v.info.passportNumber.required && submitCheck">Вкажіть серію та номер паспорта</small>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="form-group">
                                                            <input v-model="info.passportCompany" type="text" placeholder="Ким виданий" :class="['form-control', {invalid: (!$v.info.passportCompany.required && submitCheck)}]">
                                                            <small class="error" v-if="!$v.info.passportCompany.required && submitCheck">Вкажіть ким виданий</small>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="form-group">
                                                            <input v-model="info.passportDate" v-mask="'##.##.####'" type="text" placeholder="Дата видачі" :class="['form-control', {invalid: (!$v.info.passportDate.required && submitCheck)}]">
                                                            <small class="error" v-if="!$v.info.passportDate.required && submitCheck">Вкажіть дату видачі</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row col-lg-12 order-nav">
                                                    <div class="col-lg-6 text-center">
                                                        <button type="button" class="btn btn-blue" @click="submitHandler('next')">
                                                            Перейти до наступного кроку
                                                        </button>
                                                    </div>
                                                    <div class="col-lg-6 text-center">
                                                        <button type="button" class="btn btn-outline-secondary" @click="submitHandler('save')">
                                                            Зберегти чернетку
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>


<script>

//TODO добавить возможность отбращения по абсолютному пути
import Multiselect from 'vue-multiselect';
import citySearch from '@/components/app/forms/CitySearch.vue'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import {email, maxLength, minLength, numeric, required, requiredIf} from 'vuelidate/lib/validators';
import { orderBus } from '@/api/orderBus'

export default {
    components: {
        Multiselect,
        citySearch,
        VueTypeaheadBootstrap
    },
    data: function () {
        return {
            offerInfo: null,
            searchInfo: null,
            submitCheck: false,
            info:{
                documentType: 2,
                inn: null,
                name: null,
                surname: null,
                dateBirth: null,
                phone: null,
                mail: null,
                city: null,
                street: null,
                house: null,
                apartment: null,
                internationalPassport: null,
                passportNumber: null,
                passportCompany: null,
                passportDate: null
            },

        }
    },

    validations: {
        info: {
           inn:{required, minLength: minLength(10), numeric,},
           name:{required},
           surname:{required},
           dateBirth:{required, minLength: minLength(10)},
           phone:{required,minLength: minLength(19)},
           mail:{required,email},
           city:{required},
           street:{required},
           house:{required},
           apartment:{required},
           internationalPassport:{required: requiredIf(function (nestedModel) {
                    return this.info.documentType === 2
                })},
           passportNumber:{required: requiredIf(function (nestedModel) {
                    return this.info.documentType === 1
                })},
           passportCompany:{required: requiredIf(function (nestedModel) {
                    return this.info.documentType === 1
                })},
           passportDate:{required: requiredIf(function (nestedModel) {
                    return this.info.documentType === 1
                })}
        }
        //year: {required, minLength: minLength(4), maxLength: maxLength(4), numeric}
    },

    created() {
        this.offerInfo = this.$store.getters.BUS_INFO_OFFER;
        this.searchInfo = this.$store.getters.BUS_SEARCH_PARAMS;
        this.fetchInfo(this.$store.getters.BUS_INFO_USER);
    },

    mounted() {
        $('[data-toggle="tooltip"]').tooltip({
            html: true
        });
    },

    computed: {
        cityStore() {
            return this.info.city
        },
    },

    methods: {
        submitHandler(type) {
            this.submitCheck = true;
            if (this.$v.$invalid) {
                this.$v.$touch()

                return
            }

            let offerInfo = this.offerInfo;
            let searchInfo = this.searchInfo;
            let userInfo = this.info;

            this.$store.commit('SET_BUS_INFO_USER', userInfo);

            orderBus(offerInfo, searchInfo, userInfo).then((response) => {
                this.$store.commit('SET_BUS_FULL_ORDER', response.data.id);
                if(type === 'next'){
                    this.$router.push({name: 'busFinnaly'});
                }else{
                    this.$alert('Чернетка успішно збережена.')
                }
            }).catch((error) => {
                this.$alert('Щось пішло не так. Перевірте заповнені поля.')
            });
        },

        fetchInfo(data){
            if(data){
                this.info = data;
            }
        },

        citySelect(data) {
            this.info.city = data.city;
        },

    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
