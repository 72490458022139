export function orderBus(offerInfo, searchInfo, userInfo, orderId) {
    return axios({
        url: `/api/test/insurance/bus/order/create`,
        method: 'post',
        data: {
            orderId: orderId,
            companyId: offerInfo.id,
            price: offerInfo.price,
            franchise: 0,
            dmcPrice: (offerInfo.fullPrice) ?  offerInfo.dmc : null,
            fromCityId: searchInfo.from.id,
            toCityId: searchInfo.to.id,
            startDate: searchInfo.startDate,
            endDate: searchInfo.endDate,
            ticketNumber: searchInfo.ticketNumber,
            ticketPrice: searchInfo.ticketPrice,
            info: {
                inn: userInfo.inn,
                name: userInfo.name,
                surname: userInfo.surname,
                dateBirth: userInfo.dateBirth,
                phone: userInfo.phone,
                mail: userInfo.mail,
                cityId: userInfo.city.id,
                street: userInfo.street,
                house: userInfo.house,
                apartment: userInfo.apartment,
                documentType: userInfo.documentType,
                passportNumber: userInfo.passportNumber,
                passportCompany: userInfo.passportCompany,
                passportDate: userInfo.passportDate,
                internationalPassport: userInfo.internationalPassport,
            }
        },
    });
}
